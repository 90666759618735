import React from 'react'
const HomePage = () => {
  return (
    <div className=''>

    </div>
  )
}

export default HomePage
