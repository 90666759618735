import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import 'antd/dist/reset.css'
import RouteSwitch from './router'
import { HashRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'

const root = ReactDOM.createRoot(document.getElementById('root'))
const customTheme = {
  token: {
    fontFamily: "AlimamaDaoLiTi, 'Segoe UI', 'Roboto',  'Ubuntu', sans-serif",
    fontSize: 16,
  },
}
root.render(
  <React.StrictMode>
    <HashRouter>
      <ConfigProvider theme={customTheme}>
        <RouteSwitch />
      </ConfigProvider>
    </HashRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
